.App {
  overflow-y: hidden;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.page_loading_animation .loading_animation {
  background-image: url("./img/favicon.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 16%;
  height: 22vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
nav {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: #24252694;
}
.navbar-brand img {
  width: 65%;
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
}

nav .wrapper {
  position: relative;
  max-width: 1300px;
  padding: 0px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.wrapper .nav-links {
  display: inline-flex;
  margin: 0;
  padding: 0;
}
.nav-links li {
  list-style: none;
}
.nav-link:focus,
.nav-link:hover {
  color: #faebd7;
}
.image-wrap .card .content .star .btn {
  margin: 3% 0;
}
.nav-links li a {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
  background: rgba(51, 51, 51, 0) !important;
}

.nav-links li a:hover {
  background: transparent;
  outline: none;
}

.nav-links .mobile-item {
  display: none;
}
.nav-content {
  padding: 0 0 0 8px;
}
.nav-links .drop-menu {
  position: absolute;
  background: #242526;
  line-height: 45px;
  top: 85px;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.nav-links .drop-menu-1 {
  width: 250px;
  padding: 10px;
  border-radius: 5px;
}
.nav-links .drop-menu-2 {
  width: 315px;
  padding: 10px;
  border-radius: 5px;
}
.nav-links .drop-menu-3 {
  width: 348px;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  right: 25px;
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a span img {
  width: 20%;
  height: 5vh;
  border-radius: 5px;
}
.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0;
  font-weight: 400;
  border-radius: 0px;
}
.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content {
  background: #242526;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}
.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}
.row .mega-links li {
  padding: 0 20px;
}
.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover {
  color: #f2f2f2;
}
.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn {
    display: block;
  }
  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item {
    display: none;
  }
  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover {
    background: #3a3b3c;
  }
  .drop-menu li {
    margin: 0;
  }
  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }
  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li {
    margin: 0;
  }
  .content .row header {
    font-size: 19px;
  }
}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}
/* .Navigation Start*/
/* Scroll bar Start */

.navigation-bar .black {
  background: rgb(250 235 215);
  color: #000;
  padding: 0 20px;
}
.navigation-bar {
  width: 100%;
  position: fixed;
  z-index: 100;
}
.blue {
  position: fixed;
  top: 0;
  background: rgba(128, 128, 128, 0.164) !important;
  color: #000;
  width: 100%;
  height: 50px;
}
/* .nav-link {
  background: none !important;
} */
/* Scroll bar End */
.navigation-bar .navbar a img {
  width: 50%;
}
.navbar-expand-lg .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  justify-content: center;
}
.navbar-expand-lg .navbar-nav .nav-item-1 ul {
  width: 190%;
  background: #ffffffc2;
}
.navbar-expand-lg .navbar-nav .nav-item-2 ul {
  width: 170%;
  background: #ffffffc2;
}
.navbar-expand-lg .navbar-nav .nav-item-3 ul {
  width: 200%;
  background: #ffffffc2;
}
.navbar-expand-lg .navbar-nav .nav-item-1 ul li .nav-link span img {
  width: 20%;
  height: 4vh;
  margin: 0px 10px 0 0;
  border-radius: 6px;
}
.navbar-expand-lg .navbar-nav .nav-item-2 ul li .nav-link span img {
  width: 20%;
  height: 4vh;
  margin: 0px 10px 0 0;
  border-radius: 6px;
}
.navbar-expand-lg .navbar-nav .nav-item-3 ul li .nav-link span img {
  width: 20%;
  height: 4vh;
  margin: 0px 10px 0 0;
  border-radius: 6px;
}

/* .Navigation End */

/* Home Start */
.frame {
  height: 100vh;
  width: 100%;
  background-color: #eee;
  background-position: center;
  background-size: cover;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  animation: image 10s infinite alternate;
  color: #fff;
}

@keyframes image {
  0% {
    background-size: cover;
    background-image: linear-gradient(
        0deg,
        rgba(241, 236, 239, 0.3),
        rgba(21, 21, 21, 0.3)
      ),
      url("./img/bg-banner-1.jpg");
  }
  50% {
    background-size: cover;
    background-image: linear-gradient(
        0deg,
        rgba(241, 236, 239, 0.3),
        rgba(21, 21, 21, 0.3)
      ),
      url("./img/bg-banner-2.jpg");
  }
  100% {
    background-size: cover;
    background-image: linear-gradient(
        0deg,
        rgba(241, 236, 239, 0.3),
        rgba(21, 21, 21, 0.3)
      ),
      url("./img/bg-banner-3.jpg");
  }
}
.frame .home-background h4 {
  font-size: 28px;
}
.frame .home-background h1 {
  font-size: 72px;
}
.head-button {
  background-color: #fd4c5c;
  color: #fff;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  margin: 30px 0;
}

/* Down Scroll Start */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.scroll-prompt {
  position: absolute;
  z-index: 0;
  bottom: -80px;
  left: 50%;
  margin-left: -80px;
  width: 160px;
  height: 160px;
}
.scroll-prompt .scroll-prompt-arrow-container {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -18px;
  animation-name: bounce;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.scroll-prompt .scroll-prompt-arrow {
  animation-name: opacity;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.scroll-prompt .scroll-prompt-arrow:last-child {
  animation-direction: reverse;
  margin-top: -6px;
}
.scroll-prompt .scroll-prompt-arrow > div {
  width: 36px;
  height: 36px;
  border-right: 8px solid #ffffff;
  border-bottom: 8px solid #ffffff;
  transform: rotate(45deg) translateZ(1px);
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(3px);
  }
  20% {
    transform: translateY(6px);
  }
  30% {
    transform: translateY(9px);
  }
  40% {
    transform: translateY(12px);
  }
  50% {
    transform: translateY(15px);
  }
  60% {
    transform: translateY(18px);
  }
  70% {
    transform: translateY(21px);
  }
  80% {
    transform: translateY(24px);
  }
  90% {
    transform: translateY(27px);
  }
  100% {
    transform: translateY(30px);
  }
}
/* Down Scroll End */
/* Card Hover Start */

.containered {
  width: 90%;
  max-width: 1260px;
  margin: 0 auto;
}
.card_outer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 20px;
}
.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  min-height: 400px;
  text-align: center;
  color: #fff;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);
  margin: 50px 1.5px;
  height: 0vh;
  transition-duration: 4s;
  /* transition-delay: 2s; */
}
.image-wrap {
  margin: 10px;
}
.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-size: cover;
  background-position: 0 0;
  transition: transform calc(var(--d) * 1.5) var(--e);
  pointer-events: none;
}
.card:after {
  opacity: 0;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateY(-50%);
  transition: transform calc(var(--d) * 2) var(--e);
}
.card:hover:after {
  opacity: 1;
  transition: opacity calc(var(--d) * 1.5) var(--e);
}
.countrys-card .item .image-wrap .bg-card-1:before {
  background-image: url(https://i.ibb.co/ZSj9byb/Copenhagen-636x426.jpg);
}
.countrys-card .item .image-wrap .bg-card-2:before {
  background-image: url(https://i.ibb.co/nPNvGf9/Hamburg-636x426.jpg);
}
.countrys-card .item .image-wrap .bg-card-3:before {
  background-image: url(https://i.ibb.co/n1n1bVs/Netherlands-636x426.jpg);
}
.countrys-card .item .image-wrap .bg-card-4:before {
  background-image: url(https://i.ibb.co/Y7tCQDb/tour-hours-1-636x426.jpg);
}
.countrys-card .item .image-wrap .bg-card-5:before {
  background-image: url(https://i.ibb.co/d2j2hPm/tour-hours-2-636x426.jpg);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0rem;
  z-index: 1;
  transition: all 0.5s linear;
}
.content > * + * {
  margin-top: 1rem;
}

.title {
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
}

.copy {
  font-size: 15px;
  font-style: italic;
  line-height: 25px;
}
.image-wrap .card .content .star .btn {
  cursor: pointer;
  margin-top: 15px;
  padding: 10px !important;
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white !important;
  background-color: black;
  border: none;
}
.btn:hover {
  background-color: #0d0d0d;
}
@media (hover: hover) and (min-width: 600px) {
  .card:after {
    transform: translateY(0);
  }
  .content {
    transform: translateY(calc(120% - 4.5rem));
  }
  .content > *:not(.title) {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform var(--d) var(--e), opacity var(--d) var(--e);
  }
  .copy {
    text-align: left;
  }
  .image-wrap .card .content img {
    width: 25px;
  }
  .image-wrap .card .content .star {
    display: flex;
  }
  .card:hover,
  .card:focus-within {
    align-items: center;
  }
  .card:hover:before,
  .card:focus-within:before {
    transform: translateY(-4%);
  }
  .card:hover:after,
  .card:focus-within:after {
    transform: translateY(-50%);
  }
  .card:hover .content,
  .card:focus-within .content {
    transform: translateY(70px);
    transition: all 0.5s linear;
  }
  .card:hover .content > *:not(.title),
  .card:focus-within .content > *:not(.title) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(var(--d) / 8);
  }

  .card:focus-within:before,
  .card:focus-within:after,
  .card:focus-within .content,
  .card:focus-within .content > *:not(.title) {
    transition-duration: 0s;
  }
}
.owl-nav .owl-prev {
  position: absolute;
  bottom: 250px;
  left: -70px;
  font-size: 70px !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.owl-nav .owl-next {
  position: absolute;
  top: 190px;
  right: -70px;
  font-size: 70px !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: none !important;
  color: none !important;
  text-decoration: none;
}
/* Card Hover End */

/* Overview Place Start */
.overview-place {
  padding: 3% 0;
}
.overview-place-card {
  padding: 0 15px 0 0;
}
.overview-place .carded img {
  width: 30%;
}
.overview-place .carded {
  background: #ccc6;
  padding: 4%;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #ccc;
}
.overview-place .carded:hover {
  box-shadow: 0px 0px 22px 0px #ccccccab;
  cursor: pointer;
}
.overview-card {
  margin: 30px 0;
}
.overview-place .carded p {
  margin: 7px 0;
}
/* Overview Place End */
/* Budget Start */
.budget-card {
  text-align: center;
  background: aliceblue;
  padding: 30px 0;
  border-radius: 10px;
}
.budget-card .row {
  padding: 30px 0 0 0;
}
/* Budget End */

/* Home End */
/* Footer Start */
.Footer {
  margin: 3% 0 0 0;
}
.hifen_bar {
  margin: 0 15px;
}
.footer_logo {
  margin: 0 auto;
  width: 50%;
  display: block;
  margin-bottom: 26px;
}
.footer-home-link a {
  color: #000;
  line-height: 26px;
}
.footer-home-link a:hover {
  color: #000000;
  text-decoration: none;
}
.Footer .row {
  display: flex;
  justify-content: space-between;
}
.footer .slides-form.newsletter input,
.sidebar .slides-form.newsletter input {
  max-width: calc(100% - 135px);
}
.footer .slides-form.newsletter input,
.sidebar .slides-form.newsletter input {
  font-size: 17px;
  padding: 14px 18px;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  transition: 0.3s border-color, 0.3s box-shadow;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.03);
  margin-right: 0;
}
.button.flat {
  box-shadow: none !important;
  width: 0%;
  position: relative;
  right: 40px;
  font-size: 0;
  color: #000;
}
.slide .button,
.popup .button {
  margin-left: 5px;
  margin-right: 5px;
}
.blue.pale {
  background-color: #d8e7fc00 !important;
  color: #000000 !important;
}
.rounded {
  border-radius: 0px !important;
  width: 65%;
  padding: 11px;
  border: none;
  border-bottom: 1px solid #000;
  background: transparent;
}
.button {
  display: inline-block;
  border-radius: 5px;
  padding: 13px 20px;
  color: #fff;
  border: none;
  outline: none;
  text-decoration: none;
  background: #262626;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  letter-spacing: normal !important;
  position: relative;
  white-space: nowrap;
  transform: translate3d(0, 0, 0);
  box-shadow: 0px 10px 20px rgba(10, 33, 65, 0.05),
    0px 0px 2px rgba(0, 0, 0, 0.13);
  /* -webkit-appearance: none; */
  margin-bottom: 10px;
  transition: 0.3s;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.footer-address-link a {
  color: #000;
  text-decoration: none;
}
.footer-home-link {
}
.budget {
  margin: 0 0 50px 0;
}

.hero {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.social-links {
  display: flex;
  margin: 15px 0;
}

.social-links a {
  width: 45px;
  height: 45px;
  text-align: center;
  text-decoration: none;
  color: #000;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
  margin: 0 10px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  transition: transform 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-links a .fab {
  font-size: 20px;
  z-index: 10;
  transition: color 0.5s;
}

.social-links a::after {
  content: "";
  width: 100%;
  height: 100%;
  top: -90px;
  left: 0;
  background: #000;
  background: linear-gradient(-45deg, #ed1c94, #ffec17);
  position: absolute;
  transition: 0.5s;
}

.social-links a:hover::after {
  top: 0;
}

.social-links a:hover .fab {
  color: #fff;
}

.social-links a:hover {
  transform: translateY(-10px);
}

.copy-rights .copy-right-1 {
  display: flex;
  justify-content: start;
}
.copy-rights .copy-right-2 {
  display: flex;
  justify-content: end;
}
/* Footer End */

/* Family Packages Start */
.FamilyPackage .tour-packages .include-activity .rate-deal {
  text-align-last: left;
}
.FamilyPackage .tour-packages .content-section {
  padding: 0% 0px;
  color: #2c2f6c;
}

/* Family Packages End */
/* Family Plan Packages Start */
.SingaporeTourPackagesPlan .tour-packages .rate-deal img,
.KashmirTourPackagesPlan .tour-packages .rate-deal img,
.DubaiTourPackagesPlan .tour-packages .rate-deal img,
.ThailandTourPackagesPlan .tour-packages .rate-deal img,
.GujaratPlan .tour-packages .rate-deal img,
.ShillongPlan .tour-packages .rate-deal img,
.HimachalPlan .tour-packages .rate-deal img {
  width: 26%;
  height: 5vh;
  margin: 13px 0;
}
.SingaporeTourPackagesPlan .tour-packages .rate-deal,
.KashmirTourPackagesPlan .tour-packages .rate-deal,
.DubaiTourPackagesPlan .tour-packages .rate-deal,
.ThailandTourPackagesPlan .tour-packages .rate-deal,
.GujaratPlan .tour-packages .rate-deal,
.ShillongPlan .tour-packages .rate-deal,
.HimachalPlan .tour-packages .rate-deal {
  padding: 0 0 0 5%;
  align-items: center;
  text-align: center;
}
.SingaporeTourPackagesPlan .tour-packages .tour-packages img,
.KashmirTourPackagesPlan .tour-packages .tour-packages img,
.DubaiTourPackagesPlan .tour-packages .tour-packages img,
.ThailandTourPackagesPlan .tour-packages .tour-packages img,
.GujaratPlan .tour-packages .tour-packages img,
.ShillongPlan .tour-packages .tour-packages img,
.HimachalPlan .tour-packages .tour-packages img {
  width: 100%;
  /* height: 40vh; */
  border-radius: 20px 0px 0px 20px;
}
.SingaporeTourPackagesPlan .tour-packages .tour-packages .left-img,
.KashmirTourPackagesPlan .tour-packages .tour-packages .left-img,
.DubaiTourPackagesPlan .tour-packages .tour-packages .left-img,
.ThailandTourPackagesPlan .tour-packages .tour-packages .left-img,
.GujaratPlan .tour-packages .tour-packages .left-img,
.ShillongPlan .tour-packages .tour-packages .left-img,
.HimachalPlan .tour-packages .tour-packages .left-img {
  width: 100%;
  /* height: 40vh; */
  border-radius: 3px 20px 20px 3px;
}
.SingaporeTourPackagesPlan .tour-packages .content-section,
.KashmirTourPackagesPlan .tour-packages .content-section,
.DubaiTourPackagesPlan .tour-packages .content-section,
.ThailandTourPackagesPlan .tour-packages .content-section,
.GujaratPlan .tour-packages .content-section,
.ShillongPlan .tour-packages .content-section,
.HimachalPlan .tour-packages .content-section {
  padding: 3% 40px;
  color: #2c2f6c;
}
/* Family Plan Packages End */

.SingaporeTourPackagesPlan .tour-packages .media-page,
.ThailandTourPackagesPlan .tour-packages .media-page,
.KashmirTourPackagesPlan .tour-packages .media-page,
.DubaiTourPackagesPlan .tour-packages .media-page {
  background: none;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin: 15px 0px;
}
.SingaporeTourPackagesPlan .tour-packages .media-page:hover,
.ThailandTourPackagesPlan .tour-packages .media-page:hover,
.KashmirTourPackagesPlan .tour-packages .media-page:hover,
.DubaiTourPackagesPlan .tour-packages .media-page:hover {
  box-shadow: 0px 15px 50px 0px #70686842;
  transition: all 0.2s ease-in-out;
  position: relative;
  bottom: 3px;
}
.SingaporeTourPackagesPlan .tour-packages .left-img,
.ThailandTourPackagesPlan .tour-packages .left-img,
.KashmirTourPackagesPlan .tour-packages .left-img,
.DubaiTourPackagesPlan .tour-packages .left-img {
  width: 100%;
  height: 50vh;
  border-radius: 25px;
}
.media-img {
  padding: 0 10px 0 0;
}

.go-back .view-deal {
  padding: 5px 30px;
  border: 1px solid #cccccc00;
  border-radius: 10px;
  font-weight: 600;
  background: #faebd7;
  position: fixed;
}
.go-back .view-deal:hover {
  border: 1px solid #262626;
  transition: all 0.5s linear;
  background: #0000002e;
  box-shadow: 1px 1px 1px 1px;
  backdrop-filter: invert(1);
  color: #fff;
}

.media-img .img1 .comment {
  width: 85%;
}
.media-img .img1 .comment ul li {
  text-align: justify;
  list-style: none;
}

.include-activity .row {
  width: 100%;
}
.media-page .media-img .left-img {
  padding: 5%;
}

.price-amount-head {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.rate-deals {
  justify-content: center;
  display: flex;
}
.rate-deals .view-deals {
  background-color: #fd4c5c;
  color: #fff;
  padding: 10px 20px 10px 0;
  border: none;
  border-radius: 5px;
  margin: 5px 0;
  width: 100%;
}
.rate-deals img {
  width: 16% !important;
  height: 8vh !important;
  border-radius: 10px;
}

.view-deals .material-symbols-outlined {
  padding: 0 10px;
  position: relative;
  top: 5px;
}
.view-deals span {
  padding: 0 10px;
}
.rate-price {
  text-align: end;
}

.rate-price p span {
  text-decoration: line-through;
}

.btn-primary {
  width: 110%;
  font-size: 16px;
  font-weight: 400;
  text-transform: inherit;
  background: #fd4c5c;
  position: relative;
  bottom: 0px;
}

/* Enquiry Form 1 Start */

.modal.show .modal-dialog {
  transform: none;
  max-width: 1000px !important;
}
.modal-body {
  width: 90%;
  margin: 0 auto;
  padding: 0px;
  z-index: 10000;
}

.modal-header {
  border-bottom: none;
  display: block;
  text-align: center;
}
.modal-header h1 {
  font-size: 25px;
}
.modal-body div.elem-group.inlined {
  width: 100%;
  display: inline-block;
  /* float: left; */
  margin-left: 0%;
}
.modal-body div.elem-group {
  margin: 10px 0;
}

.modal-body label {
  display: block;
  font-family: "Nanum Gothic";
  padding-bottom: 10px;
  font-size: 1.25em;
}

.modal-body input,
select,
textarea {
  border-radius: 2px;
  border: 2px solid #777;
  box-sizing: border-box;
  font-size: 1.25em;
  font-family: "Nanum Gothic";
  width: 100%;
  padding: 10px;
}

.modal-body div.elem-group.inlined input {
  width: 100%;
  display: inline-block;
}

.modal-body button {
  height: 50px;
  background: orange;
  border: none;
  color: white;
  font-size: 1.25em;
  font-family: "Nanum Gothic";
  border-radius: 4px;
  cursor: pointer;
}

.modal-body button:hover {
  border: 2px solid black;
}

.modal-body label {
  text-align: initial !important;
}
.modal-body input,
select,
textarea {
  box-sizing: border-box;
  font-size: 14px;
  font-family: inherit;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  background: #af9d8412;
  font-weight: 500;
  color: #575d74;
  border: 1px solid #00000017;
  margin: 1% auto;
}

.form-control {
  width: 90%;
}

#checkout-date,
#checkin-date {
  color: #757575;
}
.modal-footer {
  display: flex;
  justify-content: center;
  border: none;
}
.modal-footer .btn-primary {
  width: 40%;
  font-size: 16px;
  font-weight: 400;
  text-transform: inherit;
  background: #fd4c5c;
  position: relative;
  bottom: 16px;
}

.modal-header-footer img {
  width: 30% !important;
  height: 8vh !important;
  border-radius: 10px !important;
  display: block !important;
}
.modal.show .modal-footer-content {
  transform: none;
  max-width: 60% !important;
  text-align: center;
}
.modal.show .modal-footer-dialog-centered {
  transform: none;
  max-width: 60% !important;
  display: flex !important;
  justify-content: center !important;
}

.copy-rights .row h6 {
  text-align: center;
  padding: 0;
  margin: 0px;
}
.enquiry-form-data-1 {
  text-align: left;
}
.enquiry-form-data-1 .agent-gft {
  display: flex;
  justify-content: space-around;
}
.enquiry-form-data-1 .agent-gft div {
  text-align: center;
}
.enquiry-form-data-1 .agent-gft div p {
  font-size: 15px;
  font-weight: 500;
}
.enquiry-form-data-1 .agent-gft img {
  width: 65%;
  height: 15vh;
}
.enquiry-form-data-1 h4 {
  padding: 5% 0;
}
.enquiry-form-data-2 {
  text-align: left;
}

.modal-content1 {
  background: white !important;
  border: none !important;
  padding: 3% 35px;
}
.modal-content {
  background: transparent;
  border: none !important;
}
.form-bg {
  background: #fff;
  border-radius: 5px;
  margin: 0 0 8% 0;
}
.modal-dialog-centered {
  display: flex;
  padding: 4% 0;
  align-items: center;
  font-size: 18px;
  text-align: center;
  justify-content: center;
}

.enquiry-form-bg div img {
  width: 100%;
  height: 61vh;
  border-radius: 10px;
  position: relative;
  bottom: 33px;
}
.enquiry-form-bg {
  background: url("./img/enquiryform.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 56vh;
  width: 50%;
  position: relative;
  bottom: 35px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enquiry-form-bg h5 {
  background: #ffffff7a;
  padding: 14px 60px;
}
.modal-header .btn-close:where(:focus, :focus-visible) {
  border: none;
  outline: none;
  box-shadow: none;
}
.modal-header .btn-close {
  position: relative;
  bottom: -2px;
  right: 4px;
  z-index: 100;
}
.cross-btn-bg {
  position: relative;
  right: 34px;
  top: 40px;
}
/* Enquiry Form End */

/* style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;" */

.form-group {
  /* display: flex; */
  /* justify-content: center; */
  /* margin: 0 0 0 8%; */
}
.form-group p {
  color: red;
  font-family: serif;
  width: 90%;
  margin: -8px auto;
}

.form-group input::placeholder {
  color: #595c74;
  font-weight: 500;
}

.form-group input::placeholder::after {
  content: "*";
  color: Red;
}
.form-group input::-ms-value {
  color: #000;
}

.modal-body form h4 {
  text-align: center;
  margin: 0;
  padding: 4% 0;
}
.modals_error {
  text-align: left;
  padding: 0 0 0 22px;
  color: #ff5300;
  font-size: 13px;
  font-family: serif;
}
.modal-body .row .bg-white {
  padding: 6% 0;
  background: #ffffffb3;
  border-radius: 5px;
}
.bg-images {
  padding: 0;
}
.bg-images .offers h4 {
  font-size: 9px;
  padding: 0;
  margin: 0;
  text-decoration: line-through;
  text-decoration-color: red;
}
.bg-images .offers h6 {
  font-size: 18px;
  padding: 0;
  margin: 0;
  font-family: monospace;
}
.bg-images .offers {
  position: absolute;
  bottom: 200px;
  text-align: center;
  right: 22px;
  color: #fff;
}
.bg-images h5 span {
  position: relative;
  color: #fff;
  width: 47%;
  display: block;
  font-size: 15px !important;
  padding: 0 0 0 18px;
}
.bg-images h5 {
  background-image: url("./img/offer.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 20vh;
  width: 109%;
  position: relative;
  display: flex;
  top: 95px;
  align-items: center;
}
.bg-images {
  background-image: url("./img/bg-img-1.jpg");
  height: 86vh !important;
  border-radius: 8px 0px 0px 8px;
  /* box-shadow: 0px 0px 45px 0px #000 !important; */
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  align-items: center;
  display: flex;
  position: relative;
}

.enq-btn {
  margin: 0px;
  width: 100%;
}
.enquiry-button {
  color: #fff !important;
  background: #fd4c5c !important;
  margin: 0 auto;
}
.enquiry-button:hover {
  color: #fd4c5c !important;
  background: #fff !important;
}
.modal-header {
  padding: 2%;
  background: #fff;
  border-radius: 10px;
}
/* 
.containersed1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 2rem;
}

.geometric-form {
  position: absolute;
  right: 0px;
  bottom: 220px;
  height: 110px;
  width: 110px;
  background: #fd4c5c;
  border: 1px solid #ccc;
  clip-path: polygon(
    50% 0%,
    83% 12%,
    100% 43%,
    94% 78%,
    68% 100%,
    32% 100%,
    6% 78%,
    0% 43%,
    17% 12%
  );
  box-shadow: 2px 2px 31px 5px rgba(0, 0, 0, 0.71);
}

.geometric-form {
  height: 70px;
  width: 70px;
  background-image: linear-gradient(
    to right bottom,
    #2700b7,
    #006aff,
    #009df6,
    #00c8b0,
    #12eb5d
  );
  animation: geometric-form-animation 1s alternate infinite;
  clip-path: polygon(
    50% 0%,
    83% 12%,
    100% 43%,
    94% 78%,
    68% 100%,
    32% 100%,
    6% 78%,
    0% 43%,
    17% 12%
  );
  box-shadow: 2px 2px 31px 5px rgba(0, 0, 0, 0.71);
} */

.geometric-form {
}

.search {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  height: 40px;
  border-radius: 40px;
  padding: 10px;
}

.search:hover > .search-txt {
  width: 180px;
  padding: 0 10px;
  color: wheat;
  font-family: "Pangolin", cursive;
}
.search:hover > .search-btn {
  background: white;
}
.search-btn {
  color: red;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #abb2b9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-txt {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: wheat;
  font-size: 16px;
  transition: ease-in 0.4s;
  line-height: 40px;
  width: 110px;
  font-family: "Pangolin", cursive;
}
.fas fa-search {
  font-weight: 200;
  font-size: 40px;
}

.ThailandTourPackagesPlan .enquiry-from-thailand .btn-primary {
  /* width: 15%; */
  border: 1px solid #fd4c5c;
  margin: 20px 0;
}

.price-amount-head .rate-price p {
  font-size: 20px;
  font-weight: 700;
}
.price-amount-head .rate-price p span {
  font-size: 20px;
  color: #999999;
}

.ThailandTourPackagesPlan .thailand-home .price-amount-head {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3% 0 0 0;
}
/* 
input[type=date]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-month-field{
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

input[type="date"] {
  color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "Travel date";
} */

.css-79elbk {
  position: absolute !important;
  top: 350px !important;
  right: 800px;
}
.loading-overlay {
  position: fixed;
}

.css-50etie svg {
  -webkit-animation: animation-5bz3sx 2s linear infinite;
  animation: animation-5bz3sx 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.btnPrevious,
.btnNext {
  display: inline-block;
  border: 1px solid #444348;
  border-radius: 3px;
  margin: 5px;
  color: #444348;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
}

#button-tab {
  color: #000 !important;
}
#page-top #content .nav-item {
  background: #ccc !important;
  margin: 1px;
}
/* #page-top {
  padding: 3% 0 0 0 !important;
} */

.nav-pills .nav-link {
  border: 1px solid #ccc;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000000;
  background-color: #faebd7;
}
#page-top .nav-link:hover,
#page-top .nav-link:active,
#page-top .nav-link:focus,
#page-top .nav-link:hover {
  color: #000 !important;
  transition: all 0.2s linear;
}

#page-top #pills-tab {
  list-style: none;
  display: flex;
  width: 100%;
}

#page-top .tour-packages .content-section ul li {
  width: 141% !important;
  display: flex;
  line-height: 30px;
}

#page-top .inclusion-img .comment {
  width: 100% !important;
  line-height: 27px !important;
}
#page-top .inclusion-img ul li {
  text-align: justify;
  list-style: initial;
}

.inclusion-podcast .inclusion-img .fade ul {
  padding: 20px 0 0 0;
  line-height: 40px;
  width: 65%;
}

.inclusion-podcast .inclusion-img .fade ul .line-ht {
  line-height: 25px;
}

.rightDiv {
  flex: 0 0 auto;
  position: sticky;
  top: 10%;
  height: 60vh;
}

.leftDiv {
  flex: 1 1 auto;
  overflow-y: auto;
}

.scrollContent {
  height: 200px;
  margin-bottom: 20px;
}

/*Packages Summary Details Start */

.summary_details details {
  position: relative;
}

.summary_details details summary::-webkit-details-marker {
  display: none;
}

.summary_details details summary::before {
  content: "";
  position: absolute;
  left: 0;
  width: 1.5em;
  height: 1.5em;
  transition: transform 0.1s linear;
}

.summary_details summary {
  width: 100%;
  padding: 20px;
  padding-left: 25px;
  border-bottom: 1px solid #ccc;
}

.summary_details summary:focus {
  outline: none;
}

.summary_details details[open] summary:before {
  transform: rotate(90deg);
}

.summary_details details[open] > summary ~ * {
  animation: open 1s ease;
}

.summary_details p.source {
  padding-top: 50px;
  font-size: 0.75em;
  text-align: center;
}

@keyframes open {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*Packages Summary Details End */


