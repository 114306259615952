/* Thailand Page Start */

/* Home Start*/
.thailand-home {
  background-image: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0) 109.56%
    ),
    url("../img/Thailand/thailand\ home\ banner.jpg");
  background-size: 120%;
  text-align: center;
  padding: 8% 0 0% 0;
  color: #fff;
  background-repeat: no-repeat;
  height: 50vh;
}
.packages-thai {
  height: 80vh;
}
.head-button span {
  position: relative;
  top: 8px;
}
/* Home End*/
/* Thailand-content Start */
.head-content {
  font-size: 24px;
}
.ihp {
  color: rgb(20, 128, 153);
}
/* Thailand-content End */
/* Tour Packages Start */
.tour-packages {
  padding: 3% 0 0 0;
  position: relative;
}

.ThailandTourPackagesPlan {
  background: #0d6efd17;
}
.tour-packages .margins {
  background: none;
  border: 1px solid #ccc;
  border-radius: 20px;
}
.tour-packages .margins:hover {
  box-shadow: 0px 15px 50px 0px #70686842;
  transition: all 0.2s ease-in-out;
  position: relative;
  bottom: 3px;
  /* -webkit-backface-visibility: hidden; */
}
.tour-packages img {
  width: 100%;
  height: 45vh;
  border-radius: 10px;
}
.tour-packages .left-img {
  width: 100%;
  /* height: 54vh; */
  border-radius: 3px 20px 20px 3px;
}
.tour-packages .content-section {
  padding: 1% 0;
  color: #2c2f6c;
}
.tour-packages .content-section ul li {
  width: 90%;
  text-align: justify;
  list-style: none;
}
.tour-packages .content-section ul li span {
  position: relative;
  top: 6px;
}
.tour-package-card .row {
  align-items: center;
  padding: 0%;
  justify-content: center;
  cursor: pointer;
  /* margin: 0 0 3% 0; */
}
.tour-packages .content-section ul {
  list-style: none;
}

.include-activity h6 {
  color: #888888;
}
.include-activity ul span {
  color: #888888;
}
.include-activity ul span .tick {
  color: #00b277;
}
.include-activity ul span .wrong {
  color: #f04f67;
}
.include-activity ul span .material-symbols-outlined {
  position: relative;
  top: 6px;
}
.include-activity {
  display: flex;
  flex-wrap: wrap;
}
.rate-deal {
  padding: 0 35px 0 0;
  text-align: end;
}
.rate-deal .view-deal:focus {
  background-color: #0a58ca;
}
.rate-deal .view-deal:hover {
  background-color: #000;
}
.rate-deal .view-deal {
  background-color: #fd4c5c;
  color: #fff;
  padding: 10px 70px;
  border: none;
  border-radius: 5px;
  margin: 5px 0;
  width: 110%;
}
.rate-deal .view-deal a {
  color: #fff;
  text-decoration: none;
}
.rate-deal p {
  font-size: 20px;
  font-weight: 700;
  color: #00b277;
  font-family: sans-serif;
}
.rate-deal p span {
  font-size: 16px;
  color: #999999;
  text-decoration: line-through;
  text-decoration-color: red;
  /* position: relative; */
  /* transform: rotate(-5deg); */
  font-family: sans-serif;
}

.rate-deal h6 strong {
  color: #999999;
  font-weight: 400;
}
.rate-deal h4 {
  font-size: 20px;
  font-weight: 700;
  color: #5581b5;
}
/* Tour Packages End */
/* Thailand Page End */

/* Dubai HoneyMoon Start */
/* Home Start*/
.DubaiHoneyMoon .thailand-home {
  background-image: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0) 109.56%
    ),
    url("../img/dubai-honey-moon/jumeirah-mina-asalam-feature.webp");
  background-size: 100%;
  text-align: center;
  padding: 13% 0 5% 0;
  color: #fff;
  background-repeat: no-repeat;
}
.head-button span {
  position: relative;
  top: 8px;
}
/* Home End*/
/* Dubai HoneyMoon End */

/* Singapore HoneyMoon Start */
/* Home Start*/
.SingaporeHoneyMoon .thailand-home {
  background-image: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0) 109.56%
    ),
    url("../img/6d4ffc61dad883af598d8a773c70bdf4--boston-skyline-singapore-travel\ \(1\).jpg");
  background-size: 100%;
  text-align: center;
  padding: 13% 0 5% 0;
  color: #fff;
  background-repeat: no-repeat;
}
.head-button span {
  position: relative;
  top: 8px;
}
/* Home End*/
/* Singapore HoneyMoon End */

.content-section ul li {
}
.accordion-item hr {
  border-top: 1px solid rgb(0 0 0 / 54%);
}
.thailand-honey-pack .accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.thailand-honey-pack .accordion-button div .days:visited,
.thailand-honey-pack .accordion-button div .days:focus,
.thailand-honey-pack .accordion-button div .days:active {
  color: #0a58ca;
}
.thailand-honey-pack .accordion-button p span {
  border: 2px solid #ccc;
  border-radius: 16px;
  padding: 0px 12px;
  margin: 0 6px;
  font-size: 10px;
}
.thailand-honey-pack .accordion-button div p {
  margin: 5px 0 0 0%;
  width: 100%;
}
.thailand-honey-pack .accordion-button {
  margin: 6px 0 0px 0;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: none !important;
  border-bottom-left-radius: none !important;
}

.thailand-honey-pack .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.thailand-honey-pack .row .col-lg-1 .vr-line {
  width: 3px;
  height: 100%;
  background: #ccc;
  margin: auto;
  position: relative;
  left: 15px;
}
.thailand-honey-pack .row .col-lg-11 img {
  width: 25%;
  margin: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}
.thailand-honey-pack .row .col-lg-11 h4 {
  font-size: 16px;
}
.thailand-honey-pack .row .col-lg-11 .Indulge {
  font-size: inherit !important;
  font-family: inherit !important;
  margin: 0 !important;
}

.price-card {
  background: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
}
.price-card h3 {
  font-size: 2rem;
  font-weight: 600;
  color: #000;
  padding-left: 10px;
  border-left: 4px solid #ff5e00;
}
.price-card h4 {
  font-size: 18px;
  padding: 5px 0px;
}
.price-card .start-from,
.price-card h6,
.price-card h5 {
  font-size: 18px;
  padding: 5px 0;
}
.price-card h5 {
  padding: 0 0 0 0;
  border-top: 1px solid #ccc;
  line-height: 8vh;
  margin: 0;
}

.price-card .off-price span {
  font-size: 12px;
  color: #fd4c5c;
  font-family: sans-serif;
}
.price-card .off-price {
  color: #00b277;
  font-size: 16px;
  font-weight: 600;
  font-family: sans-serif;
}
.price-card .off-price span {
  text-decoration: line-through;
  text-decoration-color: red;
}
.price-card .btn-close {
  background-color: transparent;
}
.price-card .btn:focus-visible {
  background-color: none;
  border-color: none;
  outline: 0;
  box-shadow: none;
}
.price-card button:where(:focus, :focus-visible, :active) {
  box-shadow: none !important;
  background-color: none !important;
  border: none !important;
}
.price-card button {
  background-color: antiquewhite;
  padding: 3% 0;
  width: 100%;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  color: #000;
}
.Highlights h1,
#accordionExample h1 {
  background: #ccc;
  padding: 10px 20px;
  font-size: 18px;
}
.tabControl,
.Highlights {
  background: #fff;
}
.Highlights ul {
  padding: 3% 0;
}

.tab-pane .fa-circle {
  font-size: 10px;
  position: relative;
  bottom: 2px;
}
.tab-pane ul {
  padding: 2% 4%;
}
.tab-pane ul ol {
  padding: 10px;
}

.tabs-panes {
  padding: 3% 4% !important;
}
.tabs-panes ul {
  padding: 0% 0% !important;
}

.tabs-panes ul ol {
  padding: 5px 4% !important;
}
