/* Default */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
img {
  width: 100%;
}

::-webkit-scrollbar {
  width: 0.1px;
}

.margin {
  background-color: antiquewhite;
  padding: 2% 0;
}
.head-2 {
  font-size: 40px;
  padding: 2% 0;
  margin: 0;
}
.head-1 {
  font-size: 18px;
  width: 100%;
}
.head-button {
  background-color: #fd4c5c;
  color: #fff;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  margin: 30px 0;
  font-weight: 700; 
}
